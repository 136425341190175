import * as React from "react"
import { Helmet } from 'react-helmet'
import { PageProps } from "gatsby"
import Menu from "../../components/Menu";

// @ts-ignore
import collectionNature from "../../images/collection-nature.webp"

import GalleryImage from "../../components/GalleryImage";
import Footer from "../../components/Footer";
import Card from "../../components/Card";

import im1 from "../../images/collections/nature-water/p1.jpg"
import im2 from "../../images/collections/nature-water/p2.jpg"
import im3 from "../../images/collections/nature-water/p3.jpg"
import im4 from "../../images/collections/nature-water/p4.jpg"
import im5 from "../../images/collections/nature-water/p5.jpg"
import im6 from "../../images/collections/nature-water/p6.jpg"
import im7 from "../../images/collections/nature-water/p7.jpg"
import im8 from "../../images/collections/nature-water/p8.jpg"
import im9 from "../../images/collections/nature-water/p9.jpg"

const Index: React.FC<PageProps> = () => {
  return (
    <div className="min-h-screen">
      {/* @ts-ignore */}
      <Helmet title="Elbae">
        <script src="https://rawcdn.githack.com/nextapps-de/spotlight/0.7.8/dist/spotlight.bundle.js"></script>
      </Helmet>
      
      <Menu />
        <main className="flex flex-wrap flex-row mt-24">
            <Card centered backgroundImage={collectionNature} />
            <Card centered>
                <h1 className="font-superclarendon text-4xl mb-6">
                    The Distortion of Nature; water
                </h1>
                <p>A collection that visualizes the beauty, the terror, the currant state and the connection of the sea. Telling the story of the sea, starting with the beauty it once had. Showing the state of the sea right now. And ending the story with hope.</p>
            </Card>

            <section className="overflow-hidden bg-secondary">
                <div className="w-full px-5 py-2 mx-auto lg:py-12 lg:px-32">
                <div className="flex flex-wrap -m-1 md:-m-2">
                    <GalleryImage src={im1} />
                    <GalleryImage src={im2} />
                    <GalleryImage src={im3} />
                    <GalleryImage src={im4} />
                    <GalleryImage src={im5} />
                    <GalleryImage src={im6} />
                    <GalleryImage src={im7} />
                    <GalleryImage src={im8} />
                    <GalleryImage src={im9} />
                </div>
                </div>
            </section>
        </main>

      <Footer />
    </div>
  );
}

export default Index
